import React from "react";
import { useTranslation } from "react-i18next";

const Component = ({ item, className, onClick }) => {
    const { t } = useTranslation();
    const Language = localStorage.getItem('i18nextLng');
    return (
        <div
            onClick={onClick}
            className={`shop-item ${className} ${item?.color}`}
            style={{ backgroundImage: `url("${item?.type[item?.color === "Purple" ? 'gold_img' : 'purple_img']}")` }}>
            <h5 className="title" >{Language == "en" ? item?.name : Language == "fr" ? item?.name_fr : item?.name_sp}</h5>
            <span className="description">{t('shop.Ouvert')}:<br /><span className="time">{`${item?.startTime} ${item?.endTime}`}</span></span>
        </div>
    );
}

export default Component;