import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  FacilityItem,
  Modal,
  OfferItem,
  Offers,
  OffersModal,
} from "../../components";
import { formatDashboard } from "../../helpers/shop";
import { useGetOffersMutation } from "../../services/offer";
import { useGetShopsMutation } from "../../services/shop";
import { setOffers } from "../../slices/offer";
import { setShops } from "../../slices/shop";

const Page = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { shops } = useSelector((state) => state.shop);
  const { offers } = useSelector((state) => state.offer);
  const { user, room, room_number } = useSelector((state) => state.user);
  const [visible, setvisible] = useState(false);
  const [getOffers, { isLoading }] = useGetOffersMutation();
  const [getShops, { isLoading: isLoading2 }] = useGetShopsMutation();

  const handleClickOffer = (i) => {
    setvisible(true);
  };

  const handleFacilityClick = (el) => {
    history.push(
      el.name === "Room Service" ? "/room-service" : `/page/${el.id}`
    );
  };

  const fetchOffers = useCallback(async () => {
    const res = await getOffers().unwrap();
    //console.log("offers", res)
    dispatch(setOffers(res));
  }, []);

  const fetchShops = useCallback(async () => {
    const res = await getShops().unwrap();
    //console.log("shops", res)
    dispatch(setShops(formatDashboard(res.data)));
  }, []);

  useEffect(() => {
    fetchOffers();
    fetchShops();
  }, []);

  return (
    <div className="home">
      <section className="section1">
        <div className="container">
          <h1 className="mb-4 mt-2">{`${t("home.Welcome to Hotel")} ${
            user.hotel?.name
          }.`}</h1>
          <h5 className="mb-4">{`${t(
            "home.Room Number"
          )} : ${room_number}`}</h5>
          {offers?.length > 0 && (
            <>
              <div className="d-flex align-items-center justify-content-between">
                <h3 className="mb-0 font-regular">{t("home.DEALS")}</h3>
                <button
                  type="button"
                  onClick={() => {
                    history.push("/offers");
                  }}
                  className="btn btn-light px-3 py-2 "
                >
                  {t("home.SEE ALL")} +
                </button>
              </div>
              <div className="row offers">
                {offers.map((el, i) => (
                  <div
                    className="col-sm-6 col-md-4 col-9 pt-4"
                    key={i.toString()}
                  >
                    <OfferItem item={el} onClick={handleClickOffer} />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </section>
      <section className="section2">
        <div className="container">
          <h3 className="mb-0 font-regular">{t("home.Facilities")}</h3>
          <div className="row g-2 facilities mb-5 py-3">
            {shops.map((el, i) => (
              <div className="col-sm-6 col-md-4 col-5" key={i.toString()}>
                <FacilityItem
                  item={el.couple[0]}
                  onClick={() => handleFacilityClick(el.couple[0])}
                />
                <FacilityItem
                  item={el.couple[1]}
                  onClick={() => handleFacilityClick(el.couple[1])}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
      <OffersModal visible={visible} onClose={() => setvisible(false)} />
    </div>
  );
};

export default Page;
