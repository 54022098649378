import React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaUser } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { toggleAuthModal } from "../../slices/global";
import { useHistory } from "react-router-dom";
import { logoutUser } from "../../slices/user";

const Page = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const { user,isAnonym,accessToken } = useSelector(state => state.user)

    return (
        <div className="profile" >
            <section className="section1" >
                <div className="container" >
                    <div className="row" >
                        <div className="col-md-6 mx-auto text-center" >
                            <div className="user mx-auto mb-3" >
                                <FaUser color={"#D5D5D5"} size={50} />
                            </div>
                            <h3 className="mb-5 text-center" >{`${user.firstname} ${user.lastname}`}</h3>
                            <button 
                                onClick={()=>history.push('/edit')}
                                className="btn btn-primary2 mx-auto mb-3 me-lg-3">{t('profile.Edit profile')}</button>
                            {/* <button className="btn btn-primary2 mx-auto mb-3">{t('profile.My informations')}</button> */}
                            <button className="btn btn-primary2 mx-auto mb-3 me-lg-3">{t('profile.Contact Us')}</button>
                            <button className="btn btn-primary2 mx-auto mb-3">{t('profile.Our website')}</button>
                            {/* <button 
                                dispatch={()=>{
                                    dispatch(logoutUser())
                                }}
                                className="btn btn-secondary mx-auto mt-5">{t('profile.Logout')}</button> */}
                        </div>
                    </div>
                    <div className="row mt-5" >
                        <div className="col-md-6 text-center mx-auto" >
                            <a href="btn btn-light ">{t("profile.Privacy Policy")}</a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Page;