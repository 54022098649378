import React from "react";
import { HiMinus, HiPlus } from "react-icons/hi";


const Component = ({ value, onChange, className }) => {
    return (
        <div className={`quantity-input d-flex align-items-center ${className}`} >
            <button 
                disabled={value == 1} 
                className={`btn btn-qty ${value==1 ? 'disabled' :''}`} 
                onClick={() => onChange(value - 1)} >
                <HiMinus size={15} color="#36013f" />
            </button>
            <span className="" >{value}</span>
            <button className="btn btn-qty" onClick={() => onChange(value + 1)} >
                <HiPlus size={15} color="#36013f" />
            </button>
        </div>
    );
}

export default Component;