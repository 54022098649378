const languages = {
    en: {
        translation: require('./en.json'),
    },
    fr: {
        translation: require('./fr.json'),
    },
    es: {
        translation: require('./es.json'),
    },
}
export default languages