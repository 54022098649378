import React, { useEffect, useMemo } from "react";
import { Route } from "react-router-dom";
import { Footer, Header } from "..";
import { toggleAuthModal } from "../../slices/global";
import { useDispatch, useSelector } from "react-redux";

const Component = ({ children, includeParts, path, ...rest }) => {
    const dispatch = useDispatch();
    const { isAnonym, accessToken } = useSelector(state => state.user)
    const PROTECTED_ROUTES = ['/chat', '/profile', '/checkout', '/edit']

    //console.log('path, isAnonym, accessTOken',path === '/:hotel_id/:hotel_code/:room_number' || (!PROTECTED_ROUTES.includes(path) && isAnonym) || (!isAnonym && accessToken))

    const isAllowed = useMemo(()=> {
        return path === '/:hotel_id/:hotel_code/:room_number' || (!PROTECTED_ROUTES.includes(path) && isAnonym) || (!isAnonym && accessToken);
    },[accessToken, isAnonym, path])

    useEffect(() => {
        if (!isAllowed) {
            dispatch(toggleAuthModal({
                visible: true,
                redirectTo:path
            }))
        }
    }, [isAllowed])

    return (
        <>
            {includeParts && <Header />}
            <Route {...rest}>
                {!isAllowed ?
                    null
                    :
                    children
                }
            </Route>
            {includeParts && <Footer />}
        </>
    );
}

export default Component;