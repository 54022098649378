import apiClient from './apiClient'

export const api = apiClient.injectEndpoints({
    endpoints: (builder) => ({
        getRequests: builder.query({
            query: (hotel_id) => ({
                url: `request_hotel`,
                method: 'GET',
            }),
        }),
        addRequest: builder.mutation({
            query: (params) => ({
                url: 'demmandUsers',
                method: 'POST',
                body: params,
            }),
        }),
    }),
    overrideExisting: true,
})

export const {
    useGetRequestsQuery,
    useAddRequestMutation
} = api
