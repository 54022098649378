import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CustomModal } from "..";
import { toggleAuthModal } from "../../slices/global";
import { useLinkUserHotelMutation, useLoginMutation, useRegisterMutation } from "../../services/user";
import { authenticateUser, setAccessToken } from "../../slices/user";
import { GoogleLogin } from 'react-google-login';
import { AiOutlineGoogle } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";
import { FACEBOOK_APP_ID, GOOGLE_CLIENT_ID } from "../../config/keys";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

const Component = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { room_number, hotel_code, hotel_id, accessToken, isAnonym } = useSelector(state => state.user)
    const { authForm } = useSelector(state => state.global)
    const { t } = useTranslation();
    const [register, { isLoading }] = useRegisterMutation();
    const [login, { isLoading: isLoading1 }] = useLoginMutation();
    const [linkUserHotel, { isLoading: isLoading2 }] = useLinkUserHotelMutation();
    const [isLogin, setIsLogin] = useState(false)
    const [linkingData, setLinkingData] = useState({})

    useEffect(() => {
        if (accessToken && linkingData.experation_date) {
            linkUser()
        }
    }, [accessToken, linkingData])

    const onClose = () => {
        dispatch(toggleAuthModal({
            visible: false,
            redirectTo: '/profile'
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)
        const params = Object.fromEntries(formData.entries())
        try {
            let res;
            if (isLogin) {
                res = await login({
                    email: params.email,
                    password: params.password
                }).unwrap()
            } else {
                res = await register({
                    firstname: params.firstname,
                    lastname: params.lastname,
                    email: params.email,
                    password: params.password,
                    hotel_id: params.hotel_id
                }).unwrap()
            }
            //console.log("res", res)
            if (res.data.token) {
                setLinkingData({...res.data.user,...params});
                dispatch(setAccessToken(res.data.token))
            }
        } catch (err) {

        }
    }

    const linkUser = async () => {
        //console.log('linkingData',linkingData)
        const link = await linkUserHotel({
            room_number: room_number || linkingData?.hotel?.reference ,
            experation_date: linkingData.experation_date,
            code: hotel_code? hotel_code: (isLogin && linkingData.hotel ? linkingData.hotel.code : null)
        }).unwrap()
        //console.log("link", link)
        if (link.room) {
            dispatch(authenticateUser({
                isAnonym: false,
                user: link.user,
                accessToken: accessToken,
                room: link.room,
                room_number,
                hotel_code,
                hotel_id,
            }))
        }
        onClose()
        history.push(authForm.redirectTo);
    }

    return (
        <CustomModal
            onClose={()=>{
                if(isAnonym){
                    history.replace('/')
                    onClose();
                }
            }}
            visible={authForm.visible}
        >
            <div className="auth-modal-con" >
                <form onSubmit={handleSubmit} >
                    <h3 className="text-center" >{t(isLogin ? 'Login' : 'Register')}</h3>
                    {!isLogin && <p className="text-center mb-4" >{t('Create an account to be able to perform orders !')}</p>}
                    <input type="hidden" name="hotel_id" value={hotel_id} />
                    <input type="hidden" name="hotel_code" value={hotel_code} />
                    <div className="mb-3">
                        <label className="form-label">{t(`Number of days`)}</label>
                        <input
                            required
                            name='experation_date'
                            type="number"
                            min={1}
                            defaultValue={4}
                            className="form-control"
                            placeholder="" />
                    </div>
                    {!isLogin &&
                        <div className="row">
                            <div className="mb-3 col-6">
                                <label className="form-label">{t(`First name`)}</label>
                                <input
                                    required
                                    name='firstname'
                                    type="text"
                                    className="form-control"
                                    placeholder="" />
                            </div>
                            <div className="mb-3 col-6">
                                <label className="form-label">{t(`Last name`)}</label>
                                <input
                                    required
                                    name='lastname'
                                    type="text"
                                    className="form-control"
                                    placeholder="" />
                            </div>
                        </div>
                    }
                    <div className="mb-3">
                        <label className="form-label">{t(`Email address`)}</label>
                        <input
                            required
                            name='email'
                            type="email"
                            className="form-control"
                            placeholder="" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">{`Password`}</label>
                        <input
                            required
                            name='password'
                            type="password"
                            className="form-control"
                            placeholder="" />
                    </div>
                    <button
                        className={`btn btn-primary my-3 w-100 ${(isLoading || isLoading1 || isLoading2) ? 'loading' : ''}`}
                        type="submit" >{t(isLogin ? 'Login' : 'Register')}</button>
                    <div className="mb-3 d-flex justify-content-center">
                        <GoogleLogin
                            clientId={GOOGLE_CLIENT_ID}
                            render={renderProps => (
                                <button
                                    className="btn btn-social"
                                    onClick={renderProps.onClick}
                                    disabled={renderProps.disabled}><AiOutlineGoogle size={20} /></button>
                            )}
                            buttonText="Login"
                            onSuccess={(res) => {
                                //console.log('GoogleLogin onSuccess', res)
                            }}
                            onFailure={(res) => {
                                //console.log('GoogleLogin onFailure', res)
                            }}
                            cookiePolicy={'single_host_origin'}
                        />
                        {/* <FacebookLogin
                            appId={FACEBOOK_APP_ID}
                            autoLoad
                            callback={(res) => {
                                console.log('FacebookLogin callback', res)
                            }}
                            render={renderProps => (
                                <button
                                    className="btn btn-social"
                                    onClick={renderProps.onClick}
                                    disabled={renderProps.disabled}><FaFacebookF size={15} /></button>
                            )}
                        /> */}
                    </div>
                    {isLogin ?
                        <div className="text-center" onClick={() => {
                            setIsLogin(false)
                        }} >{t(`You don't have an account yet ? `)}<span className="text-primary" >{t('Register')}</span></div>
                        :
                        <div className="text-center" onClick={() => {
                            setIsLogin(true)
                        }} >{t('You already have an account ? ')}<span className="text-primary" >{t('Log In')}</span></div>
                    }
                </form>
            </div>
        </CustomModal>
    );
}

export default Component;