export const formatDashboard = (data) => {
    const array = [];
    data.forEach((el, index) => {
        if (index % 2 === 0) {
            const couple = [data[index]];
            if (data[index + 1]) {
                couple.push(data[index + 1])
            }
            array.push({ couple })
        }
    });
    return array
}