import { logo } from "../../assets/images";
import { FaUser } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { FiCornerUpLeft } from "react-icons/fi";
import { useSelector } from "react-redux";

const Component = ({ back }) => {
  const history = useHistory();
  const { user } = useSelector((state) => state.user);
  //console.log(user)

  return (
    <header className="header">
      <div className="container">
        <div className="d-flex align-items-center justify-content-between">
          {back ? (
            <button
              onClick={() => {
                history.push("/room-service");
              }}
              className="btn btn-back px-2"
            >
              <FiCornerUpLeft size={26} color={"#183018"} />
            </button>
          ) : (
            <div
              className="hotel-img"
              style={user?.logo && { backgroundImage: `url(${user?.logo})` }}
            />
          )}
          <img
            onClick={() => {
              history.push("/");
            }}
            className="logo"
            src={logo}
            alt="Hotellom"
          />
          <div
            className="user"
            onClick={() => {
              history.push("/profile");
            }}
          >
            <FaUser color={"#D5D5D5"} size={18} />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Component;
