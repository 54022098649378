import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { RiSendPlaneFill } from "react-icons/ri";

const SelectedRequest = ({ handleSubmit, loading, selected }) => {
  const { t } = useTranslation();
  const [option, setoption] = useState(selected.options[0] || null);
  const [message, setmessage] = useState("");
  const Language = localStorage.getItem("i18nextLng");

  return (
    <div className="request-item">
      <div>
        <div
          className="item-icon"
          style={{ backgroundImage: `url(${selected.icon})` }}
        />
        <p>{selected.name}</p>
      </div>
      {selected.options.length > 0 && <p>{t("CHOOOSE AN OPTION")}</p>}
      {selected.options.map((op) => (
        <button
          key={op.id}
          type="button"
          className={`btn ${
            option.id == op.id ? "btn-primary" : "btn-secondary"
          }`}
          onClick={() => setoption(op)}
        >
          {Language == "en"
            ? op.name
            : Language == "fr"
            ? op.name_fr
            : op.name_sp}
        </button>
      ))}
      {selected.options.length == 0 ? (
        <>
          <p>{t("Requests.HOW CAN WE HELP YOU")}</p>
          <textarea
            className="form-control"
            value={message}
            onChange={(e) => setmessage(e.target.value)}
            placeholder={t("Requests.WRITE YOUR YOUR COMPLAIN HERE")}
          />
          <button
            type="button"
            disabled={!message}
            onClick={() => {
              setmessage("");
              handleSubmit({
                demmand_id: selected.id,
                option_id: option?.id ? option?.id : 25,
                message: `${option?.name ? `${option?.name}\n` : ""}${message}`,
              });
            }}
            className={`btn btn-primary mt-3 ${loading ? "loading" : ""}`}
          >
            {t("SEND")}
          </button>
        </>
      ) : (
        <div className="d-flex align-items-center mt-4">
          <input
            className="form-control"
            value={message}
            onChange={(e) => setmessage(e.target.value)}
            placeholder={t("Requests.WRITE YOUR COMMENT")}
          />
          <button
            type="button"
            onClick={() => {
              setmessage("");
              handleSubmit({
                demmand_id: selected.id,
                option_id: option?.id ? option?.id : 25,
                message: `${option?.name ? `${option?.name}\n` : ""}${message}`,
              });
            }}
            className={`ms-2 btn btn-secondary ${
              loading || !message ? "loading" : ""
            }`}
          >
            <RiSendPlaneFill size={20} color="#183018" />
          </button>
        </div>
      )}
    </div>
  );
};

export default SelectedRequest;
