import apiClient from './apiClient'

export const api = apiClient.injectEndpoints({
    endpoints: (builder) => ({
        getOrders: builder.mutation({
            query: (params) => ({
                url: `commands`,
                method: 'GET',
                params,
            }),
        }),
        addOrder: builder.mutation({
            query: (params) => ({
                url: `commands`,
                method: 'POST',
                body:params,
            }),
        }),
    }),
    overrideExisting: true,
})

export const {
    useGetOrdersMutation,
    useAddOrderMutation
} = api
