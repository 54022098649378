import React, { useCallback } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { OrderItem } from "../../components";
import { useGetOrdersMutation } from "../../services/order";
import { setOrders } from "../../slices/order";

const Page = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const { orders } = useSelector(state => state.order)
    const [getOrders, { isLoading }] = useGetOrdersMutation();
    const { room } = useSelector(state => state.user)

    const fetchOrders = useCallback(async () => {
        const res = await getOrders({room_id:room.id}).unwrap();
        //console.log('res',res);
        dispatch(setOrders(res))
    }, [])

    useEffect(() => {
        fetchOrders()
    }, [])

    return (
        <div className="offers" >
            <section className="section1" >
                <div className="container" >
                    <h4 className="mb-4" >{t('offers.MY ORDERS')}</h4>
                    <div className="row gx-md-2" >
                        {
                            orders.map((el, i) => (
                                <div className="col-md-6" key={i.toString()} >
                                    <OrderItem item={el} />
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Page;