import React, { useState, useEffect, useRef } from "react";
import { BiPause, BiPlay } from "react-icons/bi";
import WaveSurfer from "wavesurfer.js";

const Waveform = ({ item, isSender }) => {
  const [playing, setPlaying] = useState(false);
  const waveformRef = useRef(null);

  useEffect(() => {
    const track = document.querySelector(`#track_${item.id}`);
    waveformRef.current = WaveSurfer.create({
      barWidth: 3,
      barRadius: 3,
      barGap: 2,
      barMinHeight: 1,
      cursorWidth: 1,
      container: `#waveform_${item.id}`,
      backend: "WebAudio",
      height: 60,
      progressColor: isSender ? "#f7e7a9" : "#183018",
      responsive: true,
      waveColor: isSender ? "#f7e7a9" : "#adadad",
      cursorColor: "transparent",
    });

    waveformRef.current.load(track);

    waveformRef.current.on("finish", () => {
      setPlaying(false);
    });

    return () => {
      waveformRef.current.destroy();
    };
  }, []);

  const handlePlay = () => {
    setPlaying(!playing);
    waveformRef.current.playPause();
  };

  return (
    <div className="audio-container">
      <div
        className={`button ${isSender ? "sender" : ""}`}
        onClick={handlePlay}
      >
        {!playing ? (
          <BiPlay color={isSender ? "#36013F" : "#F5E9D4"} size={30} />
        ) : (
          <BiPause color={isSender ? "#36013F" : "#F5E9D4"} size={30} />
        )}
      </div>
      <audio id={`track_${item.id}`} src={item.content} hidden />
      <div id={`waveform_${item.id}`} className="waveform" />
    </div>
  );
};

export default Waveform;
