import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { getProductOptions } from "../../helpers/cart";
import moment from "moment";

const Component = ({ item, className }) => {
    const { t } = useTranslation();
    const [opened, setopened] = useState(false)

    const Language = localStorage.getItem('i18nextLng');

    const onClick = ()  => {
        setopened(!opened)
    }

    return (
        <div
            onClick={onClick}
            className={`my-offer-item ${className}`}>
            <div className="d-flex justify-content-between align-items-end">
                <div className="my-offer-content" >
                    <p className="title mb-1" ><b>{`${t("RS Order", { id: `#${item.id}` })} ${t("Room", { ref: `#${item.room?.room_number ? item.room?.room_number : 0}` })}`}</b></p>
                    <span className="description" >{Language == "en" ? item.command_articles[0]?.article?.description : Language == "fr" ? item.command_articles[0]?.article?.description_fr : item.command_articles[0]?.article?.description_sp}</span>
                </div>
                <div className="my-offer-right" >
                    <div className="old-price mb-0" >{item?.old_price}</div>
                    <div className="price mb-0" >{item?.price}</div>
                    <button className="btn btn-primary2 mt-auto" >{t('Details')}</button>
                </div>
            </div>
            {opened && 
            <div className="mt-3" >
                {item.command_articles?.map((el) => (
                    <div key={el.id} >
                        <div className="d-flex justify-content-between" >
                            <p>{Language == "en" ? el.article.name : Language == "fr" ? el.article.name_fr : el.article.name_sp}</p>
                            <p>{`${el.article.price}${t("MAD")}`}</p>
                        </div>
                        <div className="d-flex justify-content-between" >
                            <p>{t("order.Quantity")}</p>
                            <p>{el.quantity}</p>
                        </div>

                        {el.command_options?.length > 0 && <div className="d-flex justify-content-between" >
                            <p>{t("order.Options")}</p>
                            <p>{getProductOptions(el.command_options)}</p>
                        </div>}
                        {el.comment && <div className="d-flex justify-content-between" >
                            <p>{t("order.Note")}</p>
                            <p>{el.comment}</p>
                        </div>}
                    </div>
                ))}
                <div  >
                    <div className="d-flex justify-content-between" >
                        <p>{t("order.Order Time")}</p>
                        <p>{moment(item.created_at).format("DD/MM/YYYY HH:mm")}</p>
                    </div>
                    <div className="d-flex justify-content-between" >
                        <p>{t("order.Total")}</p>
                        <p>{`${item.total}${t("MAD")}`}</p>
                    </div>
                </div>
            </div>}
        </div>
    );

}

export default Component;