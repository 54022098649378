import { createSlice } from '@reduxjs/toolkit'

const InitialState = {
  toast: {
    visible: false,
    type: "danger",
    title: "Error",
    text: "Something went wrong !",
  },
  authForm: {
    visible: false,
    redirectTo: '/profile'
  },
  requestModal: {
    visible: false
  },
}

const slice = createSlice({
  name: 'global',
  initialState: InitialState,
  reducers: {
    setToast: (state, { payload: toast }) => {
      state.toast = { ...state.toast, ...toast }
    },
    toggleAuthModal: (state, { payload: authForm }) => {
      state.authForm = authForm
    },
    toggleRequestModal: (state, { payload: requestModal }) => {
      state.requestModal = requestModal
    },
  },
})

export const { setToast, toggleAuthModal, toggleRequestModal } = slice.actions

export default slice.reducer
