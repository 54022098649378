import i18n from 'i18next';
import languageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from 'react-i18next';
import languages from './languages';


const languageKey = "i18nextLng";

const storedLanguage = localStorage.getItem(languageKey);

i18n
    .use(languageDetector)
    .use(initReactI18next)
    .init({
        resources: languages,
        fallbackLng: "en",
        detection: {
            order: ["localStorage", "navigator"],
            caches: ["localStorage"],
            lookupLocalStorage: languageKey,
        },
        interpolation: {
            escapeValue: false
        },
        react: {
            useSuspense: false
        }
    })
    .then(() => {
        if (!storedLanguage) {
            const detectedLanguage = i18n.language.split('-')[0];
            localStorage.setItem(languageKey, detectedLanguage);
        }
    });
