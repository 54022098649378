export const handleErrorMessage = (result) => {
    if (result.payload?.data?.errors) {
        return result.payload?.data?.errors[0];
    }
    if (result.payload?.error?.data?.message) {
        return result.payload?.error?.data?.message;
    }
    if (result.payload?.data?.message) {
        return result.payload?.data?.message;
    }
    if (result.payload?.error) {
        return result.payload?.error;
    }
}