import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CustomModal } from "..";
import { setToast, toggleRequestModal } from "../../slices/global";
import { BsBack, BsCloudDownload } from "react-icons/bs";
import { useAddRequestMutation, useGetRequestsQuery } from "../../services/request";
import SelectedRequest from "./request";
import { IoReturnUpBackOutline } from "react-icons/io5";
import { FiCornerUpLeft } from "react-icons/fi";

const Component = ({ addMessage }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { requestModal } = useSelector(state => state.global)
    const { room } = useSelector(state => state.user)
    const { data: requests } = useGetRequestsQuery();
    const [addRequest, { isLoading }] = useAddRequestMutation();
    const [selected, setselected] = useState(null)
    const Language = localStorage.getItem('i18nextLng');
    
    const onClose = () => {
        dispatch(toggleRequestModal({
            visible: false,
        }))
    }

    const handleSubmit = async (params) => {
        try {
            const res = await addRequest({ ...params, room_id: room.id }).unwrap();
            await addMessage({
                type: "REQUEST",
                content: params.message,
                demmand_id: params.demmand_id,
            })
            onClose();
            dispatch(setToast({
                visible: true,
                type: "success",
                title: "Success",
                text: t('Request succefully sent!')
            }))
        } catch (error) {

        }
    }

    return (
        <CustomModal
            onClose={onClose}
            visible={requestModal.visible}
        >
            <div className="auth-modal-con" >
                <form onSubmit={handleSubmit} >
                    <div className="d-flex justify-content-between mb-3" >
                        <div
                            onClick={()=>setselected(null)}
                            className="px-2" >
                            {selected && <FiCornerUpLeft size={20} />}
                        </div>
                        <h3 className="text-center " >{t('Requests.Request')}<BsCloudDownload className="ms-2" size={20} /></h3>
                        <div className="px-2" ></div>
                    </div>
                    {!selected &&
                        <div className="d-flex justify-content-between flex-wrap" >
                            {requests?.map((el) => (
                                <div
                                    className="request-item"
                                    key={el?.id?.toString()}
                                    onClick={() => {
                                        if (el?.demmand?.options.length > 0 || !el?.demmand?.isEmpty) {
                                            setselected(el?.demmand)
                                        } else {
                                            handleSubmit({
                                                demmand_id: el?.demmand?.id,
                                                option_id: 25,
                                                message: null
                                            })
                                        }
                                    }}
                                >
                                    <div className='item-icon' style={{ backgroundImage: `url(${el.demmand?.icon})` }} />
                                    <p ><b>{Language == "en" ? el.demmand?.name : Language == "fr" ? el.demmand?.name_fr : el.demmand?.name_sp}</b></p>
                                </div>
                            ))}
                        </div>
                    }
                    {selected &&
                        <SelectedRequest
                            selected={selected}
                            handleSubmit={handleSubmit}
                            loading={isLoading}
                        />}
                </form>
            </div>
        </CustomModal>
    );
}

export default Component;