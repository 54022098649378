import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FiSearch } from "react-icons/fi";
import { HiShoppingCart } from "react-icons/hi";
import {
  AddToCartModal,
  CategoryItem,
  CustomModal,
  ProductItem,
} from "../../components";
import { BiPhoneCall } from "react-icons/bi";
import {
  useGetPopularProductsMutation,
  useGetProductsMutation,
} from "../../services/product";
import { useGetCategoriesMutation } from "../../services/category";
import { setCategories } from "../../slices/category";
import { setPopProducts, setProducts } from "../../slices/product";
import { useHistory } from "react-router-dom";

const Page = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { items } = useSelector((state) => state.cart);
  const { categories } = useSelector((state) => state.category);
  const { popProducts, products } = useSelector((state) => state.product);
  const [selectedCat, setselectedCat] = useState(null);
  const [showDetails, setShowDetails] = useState(null);
  const [adding, setAdding] = useState(null);
  const [getProducts, { isLoading }] = useGetProductsMutation();
  const [getPopularProducts, { isLoading: isLoading2 }] =
    useGetPopularProductsMutation();
  const [getCategories, { isLoading: isLoading3 }] = useGetCategoriesMutation();
  const [keyword, setKeyword] = useState("");

  const handleAdd = () => {
    setShowDetails(null);
    setAdding(showDetails);
  };

  const fetchCategories = useCallback(async () => {
    const res = await getCategories().unwrap();
    dispatch(setCategories(res.data));
  }, []);

  const fetchPopProducts = useCallback(async () => {
    const res = await getPopularProducts().unwrap();
    dispatch(setPopProducts(res));
  }, []);

  const fetchProducts = useCallback(async () => {
    const res = await getProducts({ category_id: selectedCat.id }).unwrap();
    dispatch(setProducts(res));
  }, [selectedCat?.id]);

  useEffect(() => {
    fetchCategories();
    fetchPopProducts();
  }, []);

  useEffect(() => {
    if (categories.length > 0) {
      setselectedCat(categories[0]);
    }
  }, [categories.length]);

  useEffect(() => {
    if (selectedCat?.id) {
      fetchProducts();
    }
  }, [selectedCat?.id]);

  return (
    <div className="room">
      <section className="section1">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between mb-3">
            <h2 className="mb-4">{t("room.Room Service")}</h2>
            {/* <button className="btn btn-secondary py-2 room-btn">{t('room.Room')}
                            <br />{t('room.Service')}<BiPhoneCall className="ms-2" size={25} />
                        </button> */}
          </div>
          <div className="d-flex align-items-center search-con mb-3">
            <div className="form-group me-3">
              <input
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                placeholder={t("room.Write Something")}
                name="content"
                type="text"
                className="form-control"
              />
              <FiSearch size={20} color="#183018" className="search" />
            </div>
            <button
              onClick={() => {
                history.push("/cart");
              }}
              className="btn btn-cart"
            >
              <HiShoppingCart size={20} color="#f7e7a9" />
              {items.length > 0 && (
                <span className="badge">{items.length}</span>
              )}
            </button>
          </div>
          <div className="categories">
            {categories?.map((el, i) => (
              <CategoryItem
                item={el}
                key={i.toString()}
                selected={selectedCat}
                onClick={() => setselectedCat(el)}
              />
            ))}
          </div>
          {/* {popProducts?.length > 0 &&
                    <>
                    <h4 className="font-regular" >{t('room.Most Popular')}</h4>
                    <div className={`row populars gx-2`} >
                        {
                            popProducts.map((el, i) => {
                                return (
                                    <div className="col-5 col-md-4" key={i.toString()} >
                                        <ProductItem item={el} onClick={() => setShowDetails(el)} />
                                    </div>
                                )
                            })
                        }
                    </div>
                    </>} */}
          <h4 className="mt-3 font-regular">{t("room.Meals")}</h4>
          <div className={`row meals gx-2`}>
            {products.map((el, i) => {
              if (
                !keyword ||
                el.name.toLowerCase()?.includes(keyword.toLowerCase())
              ) {
                return (
                  <div className="col-5 col-md-4" key={i.toString()}>
                    <ProductItem item={el} onClick={() => setShowDetails(el)} />
                  </div>
                );
              }
            })}
          </div>
        </div>
      </section>
      <CustomModal
        onClose={() => setShowDetails(null)}
        visible={showDetails !== null}
        onAdd={handleAdd}
      >
        <ProductItem
          large
          item={showDetails}
          onClose={() => setShowDetails(null)}
          onAdd={handleAdd}
        />
      </CustomModal>
      <AddToCartModal
        item={adding}
        onClose={() => setAdding(null)}
        visible={adding !== null}
      />
    </div>
  );
};

export default Page;
