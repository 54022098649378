import { createSlice } from '@reduxjs/toolkit'

const initialeState = {
    offers: [],
    myOffers: [],
};

const slice = createSlice({
    name: 'offer',
    initialState: initialeState,
    reducers: {
        setOffers: (
            state,
            { payload }
        ) => {
            state.offers = payload
        },
        setMyOffers: (
            state,
            { payload }
        ) => {
            state.myOffers = payload
        },
    },
})

export const { setOffers, setMyOffers } = slice.actions

export default slice.reducer