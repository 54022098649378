import apiClient from './apiClient'

export const api = apiClient.injectEndpoints({
    endpoints: (builder) => ({
        register: builder.mutation({
            query: (params) => ({
                url: 'register',
                method: 'POST',
                body: params,
            }),
        }),
        login: builder.mutation({
            query: (params) => ({
                url: 'login',
                method: 'POST',
                body: params,
            }),
        }),
        anonymousRegister: builder.mutation({
            query: (params) => ({
                url: 'registerQrd',
                method: 'POST',
                body: params,
            }),
        }),
        linkUserHotel: builder.mutation({
            query: (params) => ({
                url: 'linkUserWithHotel',
                method: 'PUT',
                body: params,
            }),
        }),
        getUser: builder.mutation({
            query: (id) => ({
                url: `users/${id}`,
                method: 'GET',
            }),
        }),
        updateUser: builder.mutation({
            query: (params) => ({
                url: `users/${params.id}`,
                method: 'PUT',
                body: params,
            }),
        }),
    }),
    overrideExisting: true,
})

export const {
    useRegisterMutation,
    useLoginMutation,
    useAnonymousRegisterMutation,
    useLinkUserHotelMutation,
    useGetUserMutation,
    useUpdateUserMutation
} = api
