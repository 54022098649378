import React from "react";
import {
    BrowserRouter as Router,
    Switch,
} from "react-router-dom";
import { AuthModal, CustomRoute, RequestsModal, ScrollToTop } from "../components";
import { Login, Home, Offers, Chat, Notifications, Profile, Orders, RoomService, Cart, Checkout, AnonymousRegister, EditProfile, Page, Assistance } from "../pages";

const AppNavigator = () => {

    return (
        <Router>
            <ScrollToTop />
            <AuthModal />
            <Switch>
                <CustomRoute includeParts path="/" exact><Home /></CustomRoute>
                <CustomRoute includeParts path="/offers"><Offers /></CustomRoute>
                <CustomRoute includeParts path="/login"><Login /></CustomRoute>
                <CustomRoute includeParts path="/chat"><Chat /></CustomRoute>
                <CustomRoute includeParts path="/assistance"><Assistance /></CustomRoute>
                <CustomRoute includeParts path="/profile" ><Profile /></CustomRoute>
                <CustomRoute includeParts path="/edit"><EditProfile /></CustomRoute>
                <CustomRoute includeParts path="/notifications"><Notifications /></CustomRoute>
                <CustomRoute includeParts path="/orders"><Orders /></CustomRoute>
                <CustomRoute includeParts path="/room-service"><RoomService /></CustomRoute>
                <CustomRoute includeParts path="/page/:id"><Page /></CustomRoute>
                <CustomRoute path="/cart"><Cart /></CustomRoute>
                <CustomRoute path="/checkout"><Checkout /></CustomRoute>
                <CustomRoute path="/:hotel_id/:hotel_code/:room_number"><AnonymousRegister /></CustomRoute>
            </Switch>
        </Router>
    );
}

export default AppNavigator;