import { createSlice } from '@reduxjs/toolkit'

const initialeState = {
    products: [],
    popProducts: [],
};

const slice = createSlice({
    name: 'product',
    initialState: initialeState,
    reducers: {
        setProducts: (
            state,
            { payload }
        ) => {
            state.products = payload
        },
        setPopProducts: (
            state,
            { payload }
        ) => {
            state.popProducts = payload
        },
    },
})

export const { setProducts, setPopProducts } = slice.actions

export default slice.reducer