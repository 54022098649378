import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Loader } from "../../components";
import { useAnonymousRegisterMutation } from "../../services/user";
import { authenticateUser } from "../../slices/user";

const Page = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { accessToken } = useSelector(state => state.user);
    const { hotel_id, hotel_code, room_number } = useParams()
    const [anonymousRegister] = useAnonymousRegisterMutation();
    const [loggedIn, setloggedIn] = useState(false)

    useEffect(() => {
        //console.log("accessToken,hotel_id,hotel_code",accessToken,hotel_id,hotel_code)
        /*if(accessToken){
            setloggedIn(true)
        }else */if (hotel_id && hotel_code) {
            register()
        }
    }, [])

    useEffect(() => {
        if (accessToken && loggedIn) {
            history.replace('/')
        }
    }, [accessToken, loggedIn])

    const register = async () => {
        const res = await anonymousRegister({
            hotel_id,
            code_hotel: hotel_code,
            room_number
        }).unwrap()
        console.log("res", res)
        if (res.data.token) {
            setloggedIn(true)
            dispatch(authenticateUser({
                hotel_id,
                hotel_code,
                room_number,
                isAnonym: true,
                user: res.data.user,
                accessToken: res.data.token
            }))
        }
    }
    return (
        <div className="anonymous" >
            <Loader />
        </div>
    );
}

export default Page;