import { store } from "../store";

export const uploadAudio = async (audio) => {
    const formData = new FormData();
    const accessToken = store.getState()?.user?.accessToken;

    formData.append('audio', audio.blob, 'audio.wav');

    const response = await fetch(`${process.env.NODE_ENV === "production" ? process.env.REACT_APP_API_ENDPOINT_PROD : process.env.REACT_APP_API_ENDPOINT_DEV}upload-audio`, {
        method: 'POST',
        body: formData,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
        },
    });

    return await response.json();
};
