import React from "react";

const Component = ({ item, selected, onClick }) => {
    const Language = localStorage.getItem('i18nextLng');

    return (
        <div onClick={onClick} className={`category-item ${selected?.id === item.id ? 'active' : ''}`} >
            <div className="img me-2" style={{ backgroundImage: `url("${item?.icon}")` }} />
            <span className="name" >{Language == "en" ? item.name : Language == "fr" ? item.name_fr : item.name_sp}</span>
        </div>
    );
}

export default Component;