export const GOOGLE_CLIENT_ID = '285768320327-6ejf5ps5amtat7f3e4qiefgv4l66vmfr.apps.googleusercontent.com';
export const FACEBOOK_APP_ID = '4742749679102505';

export const firebaseConfig = {
    apiKey: "AIzaSyAW4Z0Wzr9mhn8gouzlQQ4cVLOn2Qfp8Io",
    authDomain: "hotellom-daccd.firebaseapp.com",
    projectId: "hotellom-daccd",
    storageBucket: "hotellom-daccd.appspot.com",
    messagingSenderId: "285768320327",
    appId: "1:285768320327:web:774e24c7c7c7690350b862",
    measurementId: "G-4YFXMFXBMS"
}

export const TEST_PEAKS = [
    0.04,
    0.9,
    0.2,
    0.1,
    0.3,
    0.89,
    0.04,
    0.9,
    0.2,
    0.1,
    0.3,
    0.89,
    0.04,
    0.9,
    0.2,
    0.1,
    0.3,
    0.89,
    0.04,
    0.9,
    0.2,
    0.1,
    0.3,
    0.89,
    0.89,
    0.04,
    0.9,
    0.2,
    0.1,
    0.3,
    0.89,
];