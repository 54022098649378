import apiClient from './apiClient'

export const api = apiClient.injectEndpoints({
    endpoints: (builder) => ({
        getOffers: builder.mutation({
            query: (params) => ({
                url: 'getActiveOffers',
                method: 'GET',
                params,
            }),
        }),
        getMyOffers: builder.mutation({
            query: (params) => ({
                url: 'commandOffersByClient',
                method: 'GET',
                params,
            }),
        }),
        addOrderOffer: builder.mutation({
            query: (params) => ({
                url: 'commandOffers',
                method: 'POST',
                params,
            }),
        }),
    }),
    overrideExisting: true,
})

export const {
    useGetOffersMutation,
    useGetMyOffersMutation,
    useAddOrderOfferMutation
} = api
