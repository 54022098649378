
import React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useGetShopByIdQuery } from "../../services/shop";
import { toggleAuthModal } from "../../slices/global";
// Import the main component
import { Viewer, Worker } from '@react-pdf-viewer/core';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
// default layout plugin
// Import styles of default layout plugin
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const Page = () => {
    const { id } = useParams()
    const { t } = useTranslation();
    const { user } = useSelector(state => state.user)
    const { data } = useGetShopByIdQuery(id);
    const Language = localStorage.getItem('i18nextLng');

    return (
        <div className="home" >
            <section className="section1" >
                <div className="container" >
                    <h1 className="mb-4" >{`${t('home.Welcome to Hotel')} ${user.hotel?.name}.`}</h1>
                    <h3 className="mb-4" >{`${Language == "en" ? data?.name : Language == "fr" ? data?.name_fr : data?.name_sp}`}</h3>
                    {data?.pdf_file &&
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                            <Viewer
                                renderLoader={()=><div className="mt-5" >{'loading...'}</div>}
                                fileUrl={data?.pdf_file}
                                defaultLayout="Single" />
                        </Worker>
                    }
                </div>
            </section>
        </div>
    );
}

export default Page;