import React from "react";
import { setAccessToken, setUser } from "../../slices/user";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useUpdateUserMutation } from "../../services/user";
import { useHistory } from "react-router-dom";

const Component = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const [updateUser, { isLoading }] = useUpdateUserMutation();
    const { user } = useSelector(state => state.user)

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)
        const params = Object.fromEntries(formData.entries())
        try {
            const res = await updateUser({
                id: user.id,
                firstname: params.firstname,
                lastname: params.lastname,
                email: params.email,
            }).unwrap()
            //console.log("res",res)
            if (res.id) {
                dispatch(setUser(res))
                history.push('/profile')
            }
        } catch (err) {

        }
    }

    return (
        <div className="profile" >
            <section className="section1" >
                <div className="container" >
                    <form onSubmit={handleSubmit} >
                        <h3 className="text-center" >{t('Update')}</h3>
                        <div className="mb-3">
                            <label className="form-label">{t(`First name`)}</label>
                            <input
                                defaultValue={user.firstname}
                                required
                                name='firstname'
                                type="text"
                                className="form-control"
                                placeholder="" />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">{t(`Last name`)}</label>
                            <input
                                defaultValue={user.lastname}
                                required
                                name='lastname'
                                type="text"
                                className="form-control"
                                placeholder="" />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">{t(`Email address`)}</label>
                            <input
                                disabled={true}
                                defaultValue={user.email}
                                required
                                name='email'
                                type="email"
                                className="form-control"
                                placeholder="" />
                        </div>
                        <button
                            className={`btn btn-primary my-3 w-100 ${(isLoading) ? 'loading' : ''}`}
                            type="submit" >{t('Edit')}</button>
                    </form>
                </div>
            </section>
        </div>
    );
}

export default Component;