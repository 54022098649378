import { configureStore, isRejected } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import api from '../services/apiClient'
import slices from "../slices";
import { setToast } from '../slices/global';
import { handleErrorMessage } from '../helpers/error';

const persistConfig = {
  key: 'root1',
  storage,
  whitelist: ['cart', 'user']
}

const reducer = persistReducer(persistConfig, slices)

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        //ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        ignoredPaths: ["uploads", "uploadAudio"]
      },
    }).concat(({ getState, dispatch }) => {
      return (next) => (action) => {
        const result = next(action);
        if (isRejected(result)) {
          store.dispatch(setToast({
            visible: true,
            type: "danger",
            title: "Error",
            text: handleErrorMessage(result)
          }))
        }
        return result;
      };
    }).concat(api.middleware),
})

export const persistor = persistStore(store)