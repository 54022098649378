import React from "react";
import { logo2 } from "../../assets/images";

const Component = ({ className }) => {
    return (
        <div className={`loader ${className}`} >
            <img className="logo" src={logo2} alt="Hotellom" />
        </div>
    );
}

export default Component;