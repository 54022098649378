import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Header } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { setToast, toggleAuthModal } from "../../slices/global";
import { formatArticles, getSubTotal, getTotal } from "../../helpers/cart";
import { useAddOrderMutation } from "../../services/order";
import { resetCart } from "../../slices/cart";

const Page = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { items } = useSelector(state => state.cart)
    const { user, isAnonym, room,accessToken } = useSelector(state => state.user)
    const [type, settype] = useState('charge')
    const [addOrder, { isLoading }] = useAddOrderMutation();
    const discount = 0;
    const tax = 9;
    const subtotal = useMemo(() => getSubTotal(items), [items])
    const total = useMemo(() => getTotal(discount, tax, subtotal), [discount, tax, subtotal])

    useEffect(() => {
        if (items.length === 0) {
            history.push("/cart")
        } else if (isAnonym || !accessToken) {
            dispatch(toggleAuthModal({
                visible: true,
                redirectTo: '/checkout'
            }))
            history.push('/')
        }
    }, [items, isAnonym])

    const performOrder = async () => {
        try {
            await addOrder({
                room_id: room.id,
                hotel_id: user.hotel_id,
                status: "In preparation",
                payment: type,
                total,
                articles: formatArticles(items)
            }).unwrap();
            dispatch(setToast({
                visible: true,
                type: "success",
                title: "Success",
                text: t('Order successfully created')
            }))
            dispatch(resetCart());
        } catch (error) {
            //console.log('error',error)
        }
    }

    return (
        <div className="payment" >
            <Header back />
            <section className="section1" >
                <div className="container" >
                    <div className="px-3 px-md-0" >
                        <h2 className="mb-4" >{t('payment.Payment')}</h2>
                        <div className="row" >
                            <div className="col-md-6" >
                                <div className="total" >
                                    <h5 className="font-regular mb-2" >{t('payment.Total')} :</h5>
                                    <h2 className="mb-0" >{`${total} ${t('MAD')}`}</h2>
                                </div>
                            </div>
                            <div className="col-md-6" >
                                <h4 className="font-regular text-grey mt-5 mb-4" >{t('payment.Payment Methods')} :</h4>
                                <div className="form-check mb-3">
                                    <input
                                        checked={type == 'charge'}
                                        onChange={(e) => settype('charge')}
                                        className="form-check-input" type="radio" />
                                    <label className="form-check-label" >
                                        {t('payment.Room Charge')}
                                    </label>
                                </div>
                                <div className="form-check mb-3">
                                    <input
                                        checked={type == 'delivery'}
                                        onChange={(e) => settype('delivery')}
                                        className="form-check-input" type="radio" />
                                    <label className="form-check-label" >
                                        {t('payment.Cash On Delivery')}
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-4 text-center px-5 my-5" >
                                <button
                                    onClick={performOrder}
                                    type="button"
                                    className={`btn btn-primary2 w-100 ${isLoading ? 'loading' : ''}`} >
                                    {t('payment.Submit Order')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Page;