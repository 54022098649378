import React from "react";
import { BsStarFill, BsStar } from "react-icons/bs";

const Component = ({
  rating,
  reviews,
  className,
  editable,
  setrating,
  large,
}) => {
  return (
    <div className={`d-flex align-items-center ${className}`}>
      {Array(5)
        .fill("")
        .map((el, index) => {
          if (index + 1 <= rating) {
            return (
              <BsStarFill
                aria-disabled={!editable}
                onClick={() => setrating(index + 1)}
                key={index.toString()}
                color={"#f7e7a9"}
                className="me-1"
                size={large ? 18 : 12}
              />
            );
          } else {
            return (
              <BsStar
                aria-disabled={!editable}
                onClick={() => setrating(index + 1)}
                key={index.toString()}
                color={"#f7e7a9"}
                className="me-1"
                size={large ? 18 : 12}
              />
            );
          }
        })}
      {reviews ? `(${reviews}) Reviews` : ""}
    </div>
  );
};

export default Component;
