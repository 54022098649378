import apiClient from './apiClient'

export const api = apiClient.injectEndpoints({
    endpoints: (builder) => ({
        getCategories: builder.mutation({
            query: (params) => ({
                url: 'categories',
                method: 'GET',
                params,
            }),
        }),
    }),
    overrideExisting: true,
})

export const {
    useGetCategoriesMutation,
} = api
