import apiClient from './apiClient'

export const api = apiClient.injectEndpoints({
    endpoints: (builder) => ({
        getShops: builder.mutation({
            query: (params) => ({
                url: 'shops',
                method: 'GET',
                params,
            }),
        }),
        getShopById: builder.query({
            query: (id) => ({
                url: `shops/${id}`,
                method: 'GET',
            }),
        }),
    }),
    overrideExisting: true,
})

export const {
    useGetShopsMutation,
    useGetShopByIdQuery
} = api
