import React from "react";
import AudioWavePlayer from "../audioPlayer";


const Component = ({ item, user_id }) => {

    const Language = localStorage.getItem('i18nextLng');
    
    if (item.user.id == user_id) {
        return (
            <div className={`message-item sender`} >
                {item.demmand?.icon && <img className="img" src={item.demmand.icon} />}
                <div className={`message-content ${item.demmand?.icon ? 'is-demmand' : ''}`} >
                    {item.demmand && <p className="mb-0" ><b>{Language == "en" ? item.demmand.name : Language == "fr" ? item.demmand.name_fr : item.demmand.name_sp}</b></p>}
                    {item.type === 'AUDIO' ?
                         <AudioWavePlayer item={item} isSender />
                        : <div>{item.content}</div>}
                </div>
                {/* <span className="time" >{moment(item.createdAt).format("DD.MM.YYYY HH[h]:mm")}</span> */}
            </div>
        )
    } else {
        return (
            <div className={`message-item`} >
                {item.demmand?.icon && <img className="img" src={item.demmand.icon} />}
                <div className={`message-content ${item.demmand?.icon ? 'is-demmand' : ''}`} >
                    {item.demmand && <p className="mb-0" ><b>{Language == "en" ? item.demmand.name : Language == "fr" ? item.demmand.name_fr : item.demmand.name_sp}</b></p>}
                    {item.type === 'AUDIO' ?
                         <AudioWavePlayer item={item} />
                        : <div>{item.content}</div>}
                </div>
                {/* <span className="time" >{moment(item.createdAt).format("DD.MM.YYYY HH[h]:mm")}</span> */}
            </div>
        );
    }
}

export default Component;