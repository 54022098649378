import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const UPLOADE_ROUTES = ['uploads', 'uploadAudio'];

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.NODE_ENV === "production" ? process.env.REACT_APP_API_ENDPOINT_PROD : process.env.REACT_APP_API_ENDPOINT_DEV,
  prepareHeaders: (headers, { getState, endpoint }) => {
    const accessToken = getState().user.accessToken
    headers.set('Content-Type', UPLOADE_ROUTES.includes(endpoint) ? 'multipart/form-data' : 'application/json')

    if (accessToken) {
      headers.set('authorization', `Bearer ${accessToken}`)
    }
    return headers
  },
})

//const baseQueryWithRetry = retry(baseQuery, { maxRetries: 6 })

const api = createApi({
  baseQuery,
  endpoints: () => ({}),
})

export default api;

