import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { CustomModal, OptionChildItem, OptionItem, ProductItem } from "..";
import { addToCart } from "../../slices/cart";

const Component = ({ visible, item, onClose }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [option, setOption] = useState(null)
    const [choice, setChoice] = useState('')
    const [note, setNote] = useState('')

    useEffect(() => {
        if (visible) {
            setOption(item?.options[0]);
            if (item?.options[0]?.choices?.length > 0) {
                setChoice(item?.options[0]?.choices[0]);
            }
        }
    }, [visible])


    return (
        <CustomModal
            onClose={onClose}
            visible={visible}
        >
            <div className="add-to-cart-con" >
                <ProductItem item={item} horizontal />
                <div className="add-to-cart" >
                    <h4 className="mt-3 font-regular" >{t('room.Options')}</h4>
                    <div className="options" >
                        {item?.options.map((el, i) => (
                            <OptionItem key={el.id.toString()} item={el} onClick={() => setOption(el)} selected={option} />
                        ))}
                    </div>
                    <div className="options" >
                        {option?.choices.map((el, i) => (
                            <OptionChildItem key={el.id.toString()} item={el} onClick={() => setChoice(el)} selected={choice} />
                        ))}
                    </div>
                    <h4 className="mt-3 font-regular" >{t('room.Note')}</h4>
                    <textarea
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        placeholder={t('room.Note')}
                        rows={4}
                        className="form-control"

                    ></textarea>
                    <div className="mt-4 px-4 text-center" >
                        <button
                            onClick={() => {
                                const payload = {
                                    id: item.id,
                                    image: item.image,
                                    name: item.name,
                                    description: item.description,
                                    price: item.price,
                                    quantity: 1,
                                    comment: note,
                                    options: [option],
                                    choices: [choice]
                                }
                                //console.log('payload', payload)
                                dispatch(addToCart(payload))
                                history.push("/cart")
                            }}
                            type="button" className="btn btn-primary2 w-100" >
                            {t('room.Add To Cart')}
                        </button>
                    </div>
                </div>
            </div>
        </CustomModal>
    );
}

export default Component;