import {
  BsPlusSquare,
  BsHandbag,
  BsChat,
  BsBell,
  BsPlusSquareFill,
  BsHandbagFill,
  BsChatFill,
  BsBellFill,
} from "react-icons/bs";
import { useHistory, useLocation } from "react-router-dom";
import { logo2 } from "../../assets/images";
import { BadgeHelp } from "lucide-react";
const Component = () => {
  const location = useLocation();
  const history = useHistory();

  const handleNavigate = (route) => {
    history.push(route);
  };
  return (
    <footer className="footer">
      <a
        className={`${location.pathname === "/" ? "active" : ""}`}
        onClick={() => handleNavigate("/")}
      >
        {location.pathname === "/" ? (
          <BsPlusSquareFill size={20} />
        ) : (
          <BsPlusSquare size={20} />
        )}
      </a>
      <a
        className={`${location.pathname === "/orders" ? "active" : ""}`}
        onClick={() => handleNavigate("/orders")}
      >
        {location.pathname === "/orders" ? (
          <BsHandbagFill size={20} />
        ) : (
          <BsHandbag size={20} />
        )}
      </a>
      <a className="logo" onClick={() => handleNavigate("/")}>
        <img src={logo2} alt="Snafi" />
      </a>
      <a
        className={`${location.pathname === "/chat" ? "active" : ""}`}
        onClick={() => handleNavigate("/chat")}
      >
        {location.pathname === "/chat" ? (
          <BsChatFill size={20} />
        ) : (
          <BsChat size={20} />
        )}
      </a>
      <a
        className={`${location.pathname === "/assistance" ? "active" : ""}`}
        onClick={() => handleNavigate("/assistance")}
      >
        {location.pathname === "/assistance" ? (
          <BadgeHelp size={20} />
        ) : (
          <BadgeHelp size={20} />
        )}
      </a>
      {/* <a
        className={`${location.pathname === "/notifications" ? "active" : ""}`}
        onClick={() => handleNavigate("/notifications")}
      >
        {location.pathname === "/notifications" ? (
          <BsBellFill size={20} />
        ) : (
          <BsBell size={20} />
        )}
      </a> */}
    </footer>
  );
};

export default Component;
