import { createSlice } from '@reduxjs/toolkit'

const initialeState = { 
    shops: []
};

const slice = createSlice({
    name: 'shop',
    initialState: initialeState,
    reducers: {
        setShops: (
            state,
            { payload }
        ) => {
            state.shops = payload
        },
    },
})

export const { setShops } = slice.actions

export default slice.reducer