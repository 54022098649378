import React from "react";

const Page = () => {
    return (
        <div className="login" >
            <h1>login</h1>
        </div>
    );
}

export default Page;