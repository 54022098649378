import { createSlice } from '@reduxjs/toolkit'

const initialeState = {
    items: [],
};

const slice = createSlice({
    name: 'cart',
    initialState: initialeState,
    reducers: {
        addToCart: (
            state,
            { payload }
        ) => {
            const index = state.items.findIndex(el => el.id == payload.id)
            if (index > -1) {
                state.items[index] = { ...state.items[index], quantity: state.items[index].quantity + 1 };
            } else {
                state.items.push(payload)
            }
        },
        editCartItem: (
            state,
            { payload }
        ) => {
            const index = state.items.findIndex(el => el.id == payload.id)
            if (index > -1) {
                state.items[index] = { ...state.items[index], ...payload };
            }
        },
        deleteCartItem: (
            state,
            { payload }
        ) => {
            state.items = state.items.filter(el => el.id != payload.id)
        },
        resetCart: (
            state,
            { payload }
        ) => {
            state.items = [];
        },
    },
})

export const { addToCart, editCartItem, deleteCartItem, resetCart } = slice.actions

export default slice.reducer