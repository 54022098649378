import React from "react";
import { HiPlus } from "react-icons/hi";
import { Quantity, Review } from "..";
import { IoCloseSharp } from "react-icons/io5";
import { BiTrash } from "react-icons/bi";
import { deleteCartItem, editCartItem } from "../../slices/cart";
import { useDispatch } from "react-redux";

const Component = ({
  item,
  className,
  onClick,
  large,
  onClose,
  horizontal,
  onAdd,
  cart,
}) => {
  const dispatch = useDispatch();
  const Language = localStorage.getItem("i18nextLng");

  const handleClick = (e) => {
    //console.log(" e.target.className", e.target.className)
    if (
      typeof e.target.className == "string" &&
      e.target.className.includes("btn-plus") &&
      onAdd
    ) {
      onAdd();
    } else if (onClick) {
      onClick();
    }
  };

  const setQuantity = (quantity) => {
    dispatch(
      editCartItem({
        quantity,
        id: item.id,
      })
    );
  };

  const onDelete = () => {
    dispatch(deleteCartItem({ id: item.id }));
  };

  if (cart) {
    return (
      <div
        onClick={handleClick}
        className={`d-flex align-items-center ${className}`}
      >
        <div className={`product-item horizontal cart`}>
          <div
            className={`img bordered`}
            style={{ backgroundImage: `url("${item?.image}")` }}
          />
          <div className={`product-content`}>
            <h4 className="title font-medium mb-1">
              {Language == "en"
                ? item?.name
                : Language == "fr"
                ? item?.name_fr
                : item?.name_sp}
            </h4>
            <div className="description mb-1">
              {Language == "en"
                ? item?.description
                : Language == "fr"
                ? item?.description_fr
                : item?.description_sp}
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <h5 className="price mb-0 me-0">{`${item?.price} MAD`}</h5>
              <Quantity value={item.quantity} onChange={setQuantity} />
            </div>
          </div>
        </div>
        <button className="btn py-4 px-3" onClick={onDelete}>
          <BiTrash size={20} color="#000" />
        </button>
      </div>
    );
  }

  if (horizontal) {
    return (
      <div
        onClick={handleClick}
        className={`product-item ${className} horizontal`}
      >
        <div
          className={`img bordered`}
          style={{ backgroundImage: `url("${item?.image}")` }}
        />
        <div className={`product-content`}>
          <h4 className="title font-medium mb-1">
            {Language == "en"
              ? item?.name
              : Language == "fr"
              ? item?.name_fr
              : item?.name_sp}
          </h4>
          <div className="description mb-1">
            {Language == "en"
              ? item?.description
              : Language == "fr"
              ? item?.description_fr
              : item?.description_sp}
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <Review rating={4} />
            <h5 className="price mb-0 me-0">{`${item?.price} MAD`}</h5>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      onClick={handleClick}
      className={`product-item ${className} ${large ? "large" : ""}`}
    >
      <div
        className="img"
        style={{ backgroundImage: `url("${item?.image}")` }}
      />
      <div className={`product-content`}>
        <div
          className={`${
            large ? "d-flex align-items-center justify-content-between" : ""
          }`}
        >
          <div>
            <h4 className="title font-medium mb-2">
              {Language == "en"
                ? item?.name
                : Language == "fr"
                ? item?.name_fr
                : item?.name_sp}
            </h4>
            <div className="description mb-2">
              {Language == "en"
                ? item?.description
                : Language == "fr"
                ? item?.description_fr
                : item?.description_sp}
            </div>
          </div>
          <div>
            <Review rating={4} large={large ? true : false} />
            <div className="d-flex align-items-center justify-content-between mt-2">
              <h5 className="price mb-0 me-2">{`${item?.price} MAD`}</h5>
              <button className="btn btn-plus" onClick={onAdd}>
                <HiPlus size={20} color="#183018" />
              </button>
            </div>
          </div>
        </div>
        {large && <div className="description mt-3">{item?.content}</div>}
      </div>
      {large && (
        <div onClick={onClose} className="close">
          <IoCloseSharp color="#fff" size={20} />
        </div>
      )}
    </div>
  );
};

export default Component;
