export const getSubTotal = (items) => {
    let total = 0
    items.forEach(element => {
        total += element.price * element.quantity;
    });
    return total;
}

export const getTotal = (discount, tax, subtotal) => {
    return subtotal - discount + tax;
}

export const formatArticles = (items) => {
    const result = [];
    items.forEach((element) => {
        result.push({
            ...element,
            options: getIds(element.options),
            choices: getIds(element.choices),
        })
    });
    return result;
}

export const getProductOptions = (options) => {

    const Language = localStorage.getItem('i18nextLng');

    const names = options?.reduce(function (s, a) {
        s.push(Language == "en" ? a?.option?.name : Language == "fr" ? a?.option?.name_fr : a?.option?.name_sp);
        return s;
    }, []);
    return names?.toString()
}

export const getIds = (items) => {
    const ids = items?.reduce(function (s, a) {
        if (a?.id) {
            s.push({ id: a.id });
            return s;
        }
    }, []);
    return ids;
}