import { createSlice } from '@reduxjs/toolkit'

const initialeState = { 
    messages: []
};

const slice = createSlice({
    name: 'chat',
    initialState: initialeState,
    reducers: {
        setmessages: (
            state,
            { payload }
        ) => {
            state.messages = payload
        },
    },
})

export const { setmessages } = slice.actions

export default slice.reducer