import React from "react";

const Component = ({ title, className, children, visible, onClose }) => {

    const handleClick = (e) => {
        if (typeof e.target.className == "string" && e.target.className.includes('cus-modal-container')) {
            onClose()
        }
    }

    return (
        <div
            onClick={handleClick}
            className={`cus-modal-container  ${visible ? 'shown' : ''}`} >
            <div onClick={() => false} className={`cus-modal-content ${className}`}>
                <h3 className="mb-10 text-center" >{title}</h3>
                {children}
            </div>
        </div>
    );
}

export default Component;