import { combineReducers } from '@reduxjs/toolkit'
import api from '../services/apiClient'
import user from './user'
import global from './global'
import offer from './offer'
import shop from './shop'
import chat from './chat'
import product from './product'
import category from './category'
import cart from './cart'
import order from './order'

export default combineReducers({
    [api.reducerPath]: api.reducer,
    user,
    global,
    offer,
    shop,
    chat,
    product,
    category,
    cart,
    order
})