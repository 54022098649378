import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { IoCloseSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { setToast, toggleAuthModal } from "../../slices/global";
import { useAddOrderOfferMutation } from "../../services/offer";
import { useHistory } from "react-router-dom";

const Component = ({ item:el, className, onClick, type, onClose }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const item = el.offer ?? el
    const { isAnonym, room, accessToken } = useSelector(state => state.user)
    const { t } = useTranslation();
    const duration = `${moment(item.endDate).diff(moment(item.startDate), "days")} ${t("days")}`
    const [addOrderOffer] = useAddOrderOfferMutation();

    const handleOrder = async () => {
        if (isAnonym || !accessToken) {
            dispatch(toggleAuthModal({
                visible: true,
                redirectTo: '/'
            }))
            history.push('/')
        } else {
            await addOrderOffer({
                offer_id: item.id,
                total: item.prixFinal,
                comment: "",
                room_id: room.id,
                quantity: 1
            }).unwrap();
            dispatch(setToast({
                visible: true,
                type: "success",
                title: "Success",
                text: t('Order successfully created!')
            }))
        }
        onClose()
    }

    switch (type) {
        case "large":
            return (
                <div
                    onClick={onClick}
                    className={`offer-item ${className} large`}>
                    <div className="img" style={{ backgroundImage: `url("${item?.image}")` }} />
                    <div className="offer-content" >
                        <div className="d-flex justify-content-between align-items-start" >
                            <h4 className="title mb-2" >{item?.titre}</h4>
                            <h4 className="price mb-3" ><span className="old-price" >{`${item?.prix} ${t("MAD")}`}</span><br />{`${item?.prixFinal} ${t("MAD")}`}</h4>
                        </div>
                        <span className="description" >{item?.description}</span>
                        <div className="d-flex justify-content-center" >
                            <button
                                onClick={handleOrder}
                                className="btn btn-primary mt-4 py-2 mx-auto" >{t('offer.Order Now')}</button>
                        </div>
                        {item?.discount && <div className="discount" >{item?.discount}%</div>}
                    </div>
                    <div onClick={onClose} className="close" >
                        <IoCloseSharp color="#fff" size={20} />
                    </div>
                </div>
            );

        default:
            return (
                <div
                    onClick={onClick}
                    className={`offer-item ${className}`}>
                    <div className="img" style={{ backgroundImage: `url("${item?.image}")` }} />
                    <div className="offer-content" >
                        <h4 className="title mb-2" >{item?.titre}</h4>
                        <h4 className="price mb-3" >{`${item?.prixFinal} ${t("MAD")}`} <span className="old-price" >{`${item?.prix} ${t("MAD")}`}</span></h4>
                        <div className="d-flex justify-content-between align-items-start" >
                            <div className="description" >{t('Duration :', { duration })}</div>
                            {/* <button
                                className="btn btn-primary py-1" >{t('offer.check now')}</button> */}
                        </div>
                    </div>
                    {item?.discount && <div className="discount" >{item?.discount}%</div>}
                </div>
            );
    }

}

export default Component;