import { createSlice } from '@reduxjs/toolkit'

const initialeState = { 
    orders: []
};

const slice = createSlice({
    name: 'order',
    initialState: initialeState,
    reducers: {
        setOrders: (
            state,
            { payload }
        ) => {
            state.orders = payload
        },
    },
})

export const { setOrders } = slice.actions

export default slice.reducer