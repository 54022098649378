import { createSlice } from '@reduxjs/toolkit'

const initialeState = {
    user: {},
    accessToken: null,
    isAnonym: false,
    hotel_id: null,
    hotel_code: null,
    room_number:null,
    room:null
};

const slice = createSlice({
    name: 'auth',
    initialState: initialeState,
    reducers: {
        authenticateUser: (
            state,
            { payload: { user, accessToken, isAnonym, hotel_id, hotel_code,room_number,room } }
        ) => {
            state.user = user
            state.accessToken = accessToken
            state.isAnonym = isAnonym
            state.hotel_id = hotel_id
            state.hotel_code = hotel_code
            state.room_number = room_number
            state.room = room
        },
        setAccessToken: (
            state,
            { payload: payload }
        ) => {
            state.accessToken = payload
        },
        setUser: (state,{ payload}) => {
            state.user = payload
        },
        logoutUser: (state) => {
            state.user = initialeState.user
            state.accessToken = initialeState.accessToken
            state.isAnonym = initialeState.isAnonym
            state.hotel_id = initialeState.hotel_id
            state.hotel_code = initialeState.hotel_code
            state.room_number = initialeState.room_number
        }
    },
})

export const { setUser, authenticateUser,setAccessToken, logoutUser, setBank } = slice.actions

export default slice.reducer