import { createSlice } from '@reduxjs/toolkit'

const initialeState = { 
    categories: []
};

const slice = createSlice({
    name: 'category',
    initialState: initialeState,
    reducers: {
        setCategories: (
            state,
            { payload }
        ) => {
            state.categories = payload
        },
    },
})

export const { setCategories } = slice.actions

export default slice.reducer