import apiClient from './apiClient'

export const api = apiClient.injectEndpoints({
    endpoints: (builder) => ({
        getProducts: builder.mutation({
            query: (params) => ({
                url: `articles`,
                method: 'GET',
                params,
            }),
        }),
        getPopularProducts: builder.mutation({
            query: (params) => ({
                url: 'topApiArticles',
                method: 'GET',
                params,
            }),
        }),
    }),
    overrideExisting: true,
})

export const {
    useGetProductsMutation,
    useGetPopularProductsMutation,
} = api
