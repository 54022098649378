import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import { OfferItem } from "..";
import { useSelector } from "react-redux";
import { EffectCoverflow } from "swiper";

const Component = ({ visible, onClose }) => {
  const { offers } = useSelector((state) => state.offer);

  const handleClick = (e) => {
    if (
      typeof e.target.className == "string" &&
      e.target.className.includes("cus-modal-container")
    ) {
      onClose();
    }
  };

  return (
    <div
      onClick={handleClick}
      className={`cus-modal-container offers-modal ${visible ? "shown" : ""}`}
    >
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        modules={[EffectCoverflow]}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
        slidesPerView={offers.length > 1 ? 1.3 : 1}
        spaceBetween={30}
        centeredSlides={offers.length > 1}
      >
        {offers.map((el, i) => (
          <SwiperSlide key={el.id.toString()}>
            <OfferItem item={el} type="large" onClose={onClose} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Component;
