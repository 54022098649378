import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Header, ProductItem } from "../../components";
import { getSubTotal, getTotal } from "../../helpers/cart";
import { toggleAuthModal } from "../../slices/global";

const Page = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { items } = useSelector(state => state.cart)
    const { isAnonym, accessToken } = useSelector(state => state.user)
    const discount = 0;
    const tax = 0;
    const subtotal = useMemo(() => getSubTotal(items), [items])
    const total = useMemo(() => getTotal(discount, tax, subtotal), [discount, tax, subtotal])

    return (
        <div className="cart" >
            <Header back />
            <section className="section1" >
                <div className="container" >
                    <div className="ps-3 ps-md-0" >
                        <h2 className="mb-4" >{t('cart.Cart')}</h2>
                        <div className="row" >
                            {items.map((el, i) => (
                                <div className="col-md-6" key={i.toString()} >
                                    <ProductItem item={el} horizontal cart className={"mb-3"} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
            <section className="section2" >
                <div className="container" >
                    <div className="row" >
                        <div className="col-md-6 text-center" >
                            <div className="number my-2" >{t('cart.Room', { number: 1029 })}</div>
                        </div>
                    </div>
                </div>
                <div className="container summary" >
                    <div className="row" >
                        <div className="col-md-6 text-center" >
                            <div className='d-flex align-items-center justify-content-between px-4 pt-4' >
                                <p className="mb-0">{t('cart.Subtotal')}</p>
                                <p className="mb-0"><b>{`${subtotal} ${t("MAD")}`}</b></p>
                            </div>
                            <div className='d-flex align-items-center justify-content-between px-4 pt-4' >
                                <p className="mb-0">{t('cart.Tax')}</p>
                                <p className="mb-0"><b>{`${tax} ${t("MAD")}`}</b></p>
                            </div>
                            {discount > 0 && <div className='d-flex align-items-center justify-content-between px-4 pt-4' >
                                <p className="mb-0">{t('cart.Discount')}</p>
                                <p className="mb-0"><b>{`-${discount} ${t("MAD")}`}</b></p>
                            </div>}
                            <div className="divider mt-4" />
                            <div className='d-flex align-items-center justify-content-between px-4 pt-4' >
                                <p className="mb-0">{t('cart.Total')}</p>
                                <p className="mb-0"><b>{`${total} ${t("MAD")}`}</b></p>
                            </div>
                        </div>
                        <div className="col-md-4 text-center px-5 my-5" >
                            <button
                                disabled={items.length === 0}
                                onClick={() => {
                                    if (isAnonym || !accessToken) {
                                        dispatch(toggleAuthModal({
                                            visible:true,
                                            redirectTo:'/checkout'
                                        }))
                                    } else {
                                        history.push("/checkout")
                                    }
                                }}
                                type="button"
                                className={`btn btn-primary2 w-100 ${items.length === 0 ? 'disabled': ''}`} >
                                {t('cart.Checkout')}
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Page;