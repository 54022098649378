import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
  Avatar,
} from "@chatscope/chat-ui-kit-react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import Prompts from "../../services/Prompts";
import { useState } from "react";
import { User } from "lucide-react";

function Page() {
  const prompt = JSON.stringify([Prompts]);

  const getAvatar = (sender) => {
    return sender === "Snafi Assistance"
      ? process.env.PUBLIC_URL + "/logo2.png"
      : process.env.PUBLIC_URL + "/user.png";
  };

  const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;

  const systemMessage = {
    role: "system",
    content: prompt,
  };

  const [messages, setMessages] = useState([
    {
      message: "Hey there! I'm your trusty Snafi Assistant 😊 Ask me anything!",
      sentTime: "just now",
      sender: "Snafi Assistance",
    },
  ]);

  const [isTyping, setIsTyping] = useState(false);

  const handleSend = async (message) => {
    const newMessage = {
      message,
      direction: "outgoing",
      sender: "user",
    };

    const newMessages = [...messages, newMessage];
    setMessages(newMessages);

    setIsTyping(true);
    await processMessageToSnafi_Assistance(newMessages);
  };

  async function processMessageToSnafi_Assistance(chatMessages) {
    const apiMessages = chatMessages.map((messageObject) => {
      let role =
        messageObject.sender === "Snafi Assistance" ? "assistant" : "user";
      return { role: role, content: messageObject.message };
    });

    const apiRequestBody = {
      model: "gpt-3.5-turbo",
      messages: [systemMessage, ...apiMessages],
      temperature: 0.72,
      max_tokens: 170,
      top_p: 0.47,
      frequency_penalty: 0,
      presence_penalty: 0,
    };

    try {
      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${OPENAI_API_KEY}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(apiRequestBody),
        }
      );

      const data = await response.json();
      const assistantMessage = {
        message: data.choices[0].message.content,
        sender: "Snafi Assistance",
      };

      setMessages([...chatMessages, assistantMessage]);
    } catch (error) {
      console.error("Error fetching data from OpenAI:", error);
    } finally {
      setIsTyping(false);
    }
  }

  return (
    <section id="AssistanceChat">
      <MainContainer>
        <ChatContainer>
          <MessageList
            typingIndicator={
              isTyping && (
                <TypingIndicator content="Snafi Assistance is typing" />
              )
            }
            className="message-list"
          >
            {messages.map((message, i) => (
              <Message
                key={i}
                model={{
                  message: message.message,
                  sentTime: message.sentTime,
                  sender: message.sender,
                  direction: message.direction || "incoming",
                }}
              >
                <Avatar
                  src={getAvatar(message.sender)}
                  className="avatar"
                  status="available"
                  size="sm"
                />
              </Message>
            ))}
          </MessageList>
          <MessageInput
            placeholder="Type message here"
            onSend={handleSend}
            className="message-input"
          />
        </ChatContainer>
      </MainContainer>
    </section>
  );
}

export default Page;
