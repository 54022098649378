import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BsCloudDownload, BsMicFill } from "react-icons/bs";
import { RiSendPlaneFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { MessageItem, RequestsModal } from "../../components";
import {
  useAddMessageMutation,
  useGetMessagesQuery,
  useUploadAudioMutation,
} from "../../services/message";
import { useHistory } from "react-router-dom";
import {
  setToast,
  toggleAuthModal,
  toggleRequestModal,
} from "../../slices/global";
import { ReactMic } from "react-mic";
import { uploadAudio } from "../../services/upload";
import "wavesurfer.js/dist/wavesurfer";
import { useMemo } from "react";
import Pusher from "pusher-js";

const Page = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const listRef = useRef();
  const { t } = useTranslation();
  const { user, isAnonym, room, accessToken } = useSelector(
    (state) => state.user
  );
  const [recording, setrecording] = useState(false);
  const [showProgress, setshowProgress] = useState(false);
  const [content, setcontent] = useState("");
  const { data: messages, refetch } = useGetMessagesQuery(user.hotel_id);
  const [addMessage, { isLoading }] = useAddMessageMutation();
  const data = useMemo(
    () => (messages?.data ? Object.values(messages?.data)?.reverse() : []),
    [messages?.data]
  );

  const PlaySoundNotification = () => {
    const audio = new Audio("https://api.hotellom.com/audio/bell.mp3");

    // Handle the promise returned by play()
    audio
      .play()
      .then(() => {
        // Playback started successfully
      })
      .catch((error) => {
        // Handle the error
        console.error("Audio playback failed:", error);
      });
  };

  useEffect(() => {
    const pusher = new Pusher("ca58059b7d1eb7c94fbd", {
      cluster: "eu",
    });

    const channel = pusher.subscribe("sendClientNotif-channel" + user.hotel_id);

    channel.bind("sendClientNotif-event" + user.hotel_id, (data) => {
      refetch();
      PlaySoundNotification();
    });

    return () => {
      channel.unbind("sendClientNotif-event" + user.hotel_id);
      pusher.unsubscribe("sendClientNotif-channel" + user.hotel_id);
    };
  }, []);

  useEffect(() => {
    if (recording) {
      setTimeout(() => {
        setshowProgress(true);
      }, 100);
    } else {
      setshowProgress(false);
    }
  }, [recording]);

  useEffect(() => {
    if (data?.length > 5) {
      listRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [data?.length]);

  const handleAddMessage = async (data) => {
    try {
      const message = {
        ...data,
        room_id: room.id,
        hotel_id: user.hotel_id,
        user_id: user.id,
      };
      setcontent("");
      const res = await addMessage(message).unwrap();
      refetch();
      //console.log("addMessage res", res)
    } catch (error) {}
  };

  const onData = (recordedBlob) => {
    //console.log('chunk of real-time data is: ', recordedBlob);
  };

  const onStop = async (recordedBlob) => {
    // console.log('recordedBlob is: ', recordedBlob);
    try {
      const res = await uploadAudio(recordedBlob);
      if (res["audioUrl "]) {
        handleAddMessage({
          type: "AUDIO",
          content: res["audioUrl "],
        });
      }
    } catch (error) {}
  };

  return (
    <div className="chat">
      <section className="section1">
        <div className="container">
          <h5 className="mb-4 text-center">{`${t(
            "chat.CUSTOMERS SATISFACTION"
          )}`}</h5>
          <div className="text-center">
            {/* <button className="btn btn-secondary py-1">{t('chat.RECEPTION')}<BiPhoneCall className="ms-2" size={20} /></button> */}
            <button
              onClick={() => {
                dispatch(toggleRequestModal({ visible: true }));
              }}
              className="btn btn-secondary py-1"
            >
              {t("chat.REQUEST")}
              <BsCloudDownload className="ms-2" size={20} />
            </button>
          </div>
          <div className="mx-auto divider" />
        </div>
        <div className="chat-content container">
          {data?.map((item, index) => (
            <MessageItem item={item} user_id={user.id} key={index.toString()} />
          ))}
          <div ref={listRef} />
        </div>
        <div className="chat-actions container">
          <div className="d-flex align-items-center">
            <div className="form-group me-2">
              <input
                value={content}
                onChange={(e) => setcontent(e.target.value)}
                placeholder={t("room.Write Something")}
                name="content"
                type="text"
                className="form-control"
              />
              <button
                onClick={() =>
                  handleAddMessage({
                    type: "TEXT",
                    content,
                  })
                }
                type="submit"
                className={`btn btn-send ${
                  isLoading || !content ? "disabled" : ""
                }`}
              >
                <RiSendPlaneFill size={20} color="#183018" />
              </button>
              <div className={`audio-progress ${recording ? "recording" : ""}`}>
                <ReactMic
                  visualSetting="frequencyBars"
                  record={recording}
                  onData={onData}
                  onStop={onStop}
                  strokeColor="#183018"
                  backgroundColor="#f7e7a9"
                  className="form-group"
                  mimeType="audio/wav"
                />
              </div>
            </div>
            <div className={`audio-con`}>
              {recording ? (
                <button
                  type="button"
                  onClick={() => setrecording(!recording)}
                  className="btn btn-audio"
                >
                  <RiSendPlaneFill size={20} color="#183018" />
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() => {
                    setrecording(!recording);
                  }}
                  className="btn btn-audio"
                >
                  <BsMicFill size={20} color="#183018" />
                </button>
              )}
            </div>
          </div>
        </div>
      </section>
      <RequestsModal addMessage={handleAddMessage} />
    </div>
  );
};

export default Page;
