import apiClient from './apiClient'

export const api = apiClient.injectEndpoints({
    endpoints: (builder) => ({
        getMessages: builder.query({
            query: (hotel_id) => ({
                url: `messages?hotel_id=${hotel_id}`,
                method: 'GET',
            }),
        }),
        addMessage: builder.mutation({
            query: (params) => ({
                url: 'messages',
                method: 'POST',
                body: params,
            }),
        }),
        uploadAudio: builder.mutation({
            query: (params) => ({
                url: 'upload-audio',
                method: 'POST',
                body: params,
            }),
        }),
    }),
    overrideExisting: true,
})

export const {
    useGetMessagesQuery,
    useAddMessageMutation,
    useUploadAudioMutation
} = api
